<template>
    <div
        dir="ltr"
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-100 px-6"
    >
        <div class="-mx-6 flex shrink-0 items-end p-5 sm:mx-0 sm:border-none sm:p-6 sm:px-0">
            <NuxtLinkLocale
                to="/"
                class="flex items-center gap-2"
                @click="hideSidebarMenu"
            >
                <img
                    class="h-8 w-auto"
                    src="/images/logo.png"
                    alt="N-Issues"
                ><span class="font-medium">Issues</span>
            </NuxtLinkLocale>
        </div>
        <nav class="flex flex-1 flex-col">
            <ul
                role="list"
                class="flex flex-1 flex-col gap-y-7"
            >
                <li>
                    <ul
                        role="list"
                        class="-mx-2 space-y-1"
                    >
                        <li
                            v-for="item in props?.navigations"
                            :key="item.name"
                        >
                            <NuxtLinkLocale
                                v-if="!item.children && showSidebarMenuItem(item.name)"
                                :to="item.href"
                                class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-5 hover:bg-neutral-200 hover:text-neutral-900"
                                :class="[item.current ? 'bg-neutral-200 text-neutral-900' : 'text-neutral-500']"
                                @click="hideSidebarMenu"
                            >
                                <div
                                    class="shrink-0 group-hover:text-neutral-900"
                                    :class="{ 'text-neutral-900': item.current }"
                                >
                                    <NfsIcon
                                        :icon="item.icon"
                                        size="xsmall"
                                    />
                                </div>
                                {{ item.name }}
                            </NuxtLinkLocale>
                            <Disclosure
                                v-else-if="showSidebarMenuItem(item.name)"
                                v-slot="{ open }"
                                as="div"
                            >
                                <DisclosureButton :class="[item.current ? 'bg-neutral-200 text-neutral-900': 'text-neutral-500', 'flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700']">
                                    <div
                                        class="shrink-0 group-hover:text-neutral-900"
                                        :class="{ 'text-neutral-900': item.current }"
                                    >
                                        <NfsIcon
                                            :icon="item.icon"
                                            size="xsmall"
                                        />
                                    </div>
                                    {{ item.name }}
                                    <ChevronRightIcon
                                        :class="[open ? 'text-gray-500 rotate-90' : 'text-gray-400', 'ml-auto size-5 shrink-0']"
                                        aria-hidden="true"
                                    />
                                </DisclosureButton>
                                <DisclosurePanel
                                    as="ul"
                                    class="mt-1 px-2"
                                >
                                    <li
                                        v-for="subItem in item.children"
                                        :key="subItem.name"
                                    >
                                        <NuxtLinkLocale
                                            :to="subItem.href"
                                            class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-neutral-200 hover:text-neutral-900"
                                            :class="[subItem.current ? 'bg-neutral-200 text-neutral-900' : 'text-neutral-500']"
                                            @click="hideSidebarMenu"
                                        >
                                            <div
                                                class="shrink-0 group-hover:text-neutral-900"
                                                :class="{ 'text-neutral-900': item.current }"
                                            >
                                                <NfsIcon
                                                    :icon="subItem.icon"
                                                    size="xsmall"
                                                />
                                            </div>
                                            {{ subItem.name }}
                                        </NuxtLinkLocale>
                                    </li>
                                </DisclosurePanel>
                            </Disclosure>
                        </li>
                    </ul>
                </li>
                <li class="mt-auto pb-5">
                    <div
                        v-if="isAuthenticated"
                        class="relative"
                    >
                        <UserWidget
                            :name="user?.name"
                            :email="user?.email"
                            :avatar="user?.avatar"
                        />
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import {
    Disclosure, DisclosureButton, DisclosurePanel
} from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { NavItemObject } from '../types/navItemObject';
import { isUserPermitted } from '../composables/userPermissions';
import {
    adminUsersManagement, categories, notificationSettings
} from '../types/constants';
import { UserPermissionType } from '../../shared/shared.interface';
import { useIsAuthenticated } from '~/composables/isAuthenticated';

const showUsersManagement = ref(false);
const props = defineProps({
    navigations: {
        type: Array as PropType<NavItemObject[]>,
        default: () => ([])
    }
});
const emit = defineEmits<(e: 'action') => void>();
const user = useUser().value;

showUsersManagement.value = await isUserPermitted(UserPermissionType.MANAGE_USERS);

const hideSidebarMenu = () => {
    emit('action');
};

const showSidebarMenuItem = (itemName:string) => {
    let showMenuItem = true;
    if ((!showUsersManagement.value && itemName === adminUsersManagement.name))
        showMenuItem = false;
    else if ((!showUsersManagement.value && itemName === categories.name))
        showMenuItem = false;
    else if (!useIsAuthenticated() && itemName === notificationSettings.name)
        showMenuItem = false;

    return showMenuItem;
};

watchEffect(async() => {
    if (useIsAuthenticated())
        showUsersManagement.value = await isUserPermitted(UserPermissionType.MANAGE_USERS);
});

const isAuthenticated = useIsAuthenticated();
</script>
